import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  folder: any;
  sponsors = [];
  sent = false;
  email: any;
  name: any;
  message: any;
  columns: any;
  rowHeight: any;

  constructor(private db: AngularFirestore, private storage: AngularFireStorage) {
    const loadSponsors = this.db.collection('sponsors').valueChanges();
    loadSponsors.subscribe(sponsors => {
      this.sponsors = sponsors;
      console.log(sponsors);
    });

    this.folder = this.storage.ref('club');
  }

  ngOnInit(): void {
    this.columns = (window.innerWidth <= 400) ? 1 : 3;
    this.rowHeight = (window.innerWidth <= 400) ? "1:1" : "1:1";
  }

  onResize(event) {
    this.columns = (event.target.innerWidth <= 400) ? 1 : 3;
    this.rowHeight = (window.innerWidth <= 400) ? "1:1" : "1:1";
  }

  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }

  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }

  send() {
    const message = 'Name: ' + this.name + '<br>' +
      'Email: ' + this.email + '<br>' +
      'Message: ' + this.message;
    this.db.collection('mail').add({
      to: 'colin.moore@flyingvlabs.io',
      message: {
        subject: 'Jozo Website Inquiry',
        html: message,
      },
    });
    this.sent = true;
  }

}
