import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls: ['./sponsors.component.scss']
})
export class SponsorsComponent implements OnInit {

  folder: any;
  sponsors = [];

  constructor(private db: AngularFirestore, private storage: AngularFireStorage) {
    const loadSponsors = this.db.collection('sponsors').valueChanges();
    loadSponsors.subscribe(sponsors => {
      this.sponsors = sponsors;
      console.log(sponsors);
    });

    this.folder = this.storage.ref('club');
  }

  ngOnInit(): void {
  }

}
