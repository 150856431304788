<div class="page-header">
  <h1>Our Amazing Sponsors</h1>
</div>
<div class="page-intro">
  <p>
    It definitely takes a village! Many generous individuals and companies help make Jozo successful.
    Without their hard work, dedication and financial contributions – it would be very difficult
    to create the atmosphere and training environment that we all hope for.
    It is with great thanks that we recognize the following corporate sponsors for the
    Jozo 2019 season.
  </p>
  <p>
    <i><b>Please take a few minutes to check them out!</b></i>
  </p>
</div>
<div class="sponsors">
  <mat-grid-list cols="1" rowHeight="6rem">
      <mat-grid-tile colspan="1" [rowspan]="6" *ngFor="let sponsor of sponsors">
        <mat-card>
          <mat-card-header>
            <mat-card-title>{{sponsor.name}}</mat-card-title>
          </mat-card-header>
          <img mat-card-lg-image *ngIf="'club/'+sponsor.photo | fireStorageImage | async as photoUrl" [src]="photoUrl" width="150" height="150" />
          <mat-card-content>
            {{sponsor.description}}
          </mat-card-content>
          <mat-card-footer>
            <mat-icon>website</mat-icon><a href="{{sponsor.website}}" target="blank">{{sponsor.website}}</a>
          </mat-card-footer>
        </mat-card>
      </mat-grid-tile>
  </mat-grid-list>
</div>
