<div class="page-header">
  <h1>Members</h1>
</div>

<div *ngIf="loading">
  Loading...
</div>
<div *ngIf="error">
  Error :(
</div>

<div id="report-data">
  <div id="report-filter">
    <mat-form-field>
      <mat-label>Power Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
    </mat-form-field>
  </div>
  <!--
  <button mat-button (click)="exporter.exportTable('csv')"></button>
  -->
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
      <!-- Order Id Column -->
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef> First Name </th>
        <td mat-cell *matCellDef="let row"> {{row.firstName}} </td>
      </ng-container>

      <!-- Ingestor Column -->
      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef> Last Name </th>
        <td mat-cell *matCellDef="let row"> {{row.lastName}} </td>
      </ng-container>

      <!-- Seller Column -->
      <ng-container matColumnDef="ageGroup">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Age Group </th>
        <td mat-cell *matCellDef="let row"> {{row.ageGroup}} </td>
      </ng-container>

      <ng-container matColumnDef="registeredDateTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Registered Date </th>
        <td mat-cell *matCellDef="let row"> {{ row.registeredDateTime }} </td>
      </ng-container>

      <!-- Order Id Column ingest_time -->
      <ng-container matColumnDef="parentName">
        <th mat-header-cell *matHeaderCellDef> Parent </th>
        <td mat-cell *matCellDef="let row"> {{row.parentFirstName}} {{row.paretLastName}} </td>
      </ng-container>

      <ng-container matColumnDef="parentEmail">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let row"> {{row.parentEmail}} </td>
      </ng-container>

      <ng-container matColumnDef="parentPhoneNumber">
        <th mat-header-cell *matHeaderCellDef> Phone </th>
        <td mat-cell *matCellDef="let row"> {{row.parentPhone}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>
