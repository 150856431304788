<div class="page-header">
  <h1>Login</h1>
</div>

<div id="login">
  <h1 *ngIf="fbs.user | async">
    Welcome User: {{ (fbs.user | async)?.email }}!
  </h1>
  <div *ngIf="!(fbs.user | async)">
      <!-- these input fields takes email and password from user -->
      <input type="text" [(ngModel)]="email" placeholder="Enter email"/>
      <input type="password" [(ngModel)]="password" placeholder="Enter password"/>

      <!-- user click this button to login -->
      <button (click)="login()" [disabled]="!email || !password">
      	Login
      </button>
  </div>

  <!-- user click this button to logout -->
  <!-- this button displays only when user is logged-in -->
  <button (click)="logout()" *ngIf="fbs.user | async">
  	Logout
  </button>
</div>
