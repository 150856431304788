import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SponsorsComponent } from './sponsors/sponsors.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { InlineSVGModule } from 'ng-inline-svg';
import { OnlineStoreComponent } from './online-store/online-store.component';
import { TheClubComponent } from './the-club/the-club.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { FireStorageImagePipe } from './shared/fire-storage-image.pipe';
import { RegisterMemberComponent } from './register-member/register-member.component';
import { MembersComponent } from './members/members.component';
import { LoginComponent } from './login/login.component';
import { FirebaseService } from './services/firebase.service';
//import { AuthGuard } './auth/auth.guard';

const firebaseConfig = {
  apiKey: "AIzaSyAinWCH3-0yOcPB-WuIWkkhc4Q78OvLYlM",
  authDomain: "jozo-weider.firebaseapp.com",
  databaseURL: "https://jozo-weider.firebaseio.com",
  projectId: "jozo-weider",
  storageBucket: "jozo-weider.appspot.com",
  messagingSenderId: "301325629381",
  appId: "1:301325629381:web:58849875f6245dd8cebdac",
  measurementId: "G-6TBHTXQ3T0"
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    SponsorsComponent,
    OnlineStoreComponent,
    TheClubComponent,
    FireStorageImagePipe,
    RegisterMemberComponent,
    MembersComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatGridListModule,
    MatTabsModule,
    MatIconModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatListModule,
    MatInputModule,
    MatStepperModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableExporterModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    PdfViewerModule,
    InlineSVGModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    FormsModule
  ],
  providers: [
    DatePipe,
    FirebaseService
    //AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
