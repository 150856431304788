<div class="page-header">
  <h1>The Club</h1>
</div>

<div class="club-content">
  <mat-tab-group>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">terrain</mat-icon>
        Membership
      </ng-template>
      <p>
        The Jozo Weider Volunteer Committee (JWVC) is a group of parent volunteers who work to enhance the experience of our athletes and our parents by helping with the social aspects of the ski club. They organize most social events including Opening Weekend, Saturday Apres, Men’s Day, Ladies Day, Family Day and Year-End Awards Banquet as well as assisting with Race Day volunteer roles such as gate judges and bib collection.

        Jozo families are asked to pay a Jozo Weider Racing Club membership fee ranging between $30-$60 depending if they are a 1 or 2 day Racer, Snowboarder, Freestyle or Alumni.

        Some of the benefits that are paid for with the Membership Fees include:
      </p>
      <ul>
        <li>Jozo Branded Gift and Membership Cards</li>
        <li>Free Sponsored Events - Opening Weekend (AGM, Ski Swap and Appetizers), Family Day event with Appetizers - Subsidized Events Year-End Awards Banquet</li>
        <li>Organized Events - Apres Saturday Cash Bar in Kandahar room, Men’s Day, Ladies Day, Family Day Pot-Luck</li>
        <li>Subsidized Gear - Jozo Team Jackets and Jozo Branded Apparel & Merchandise</li>
        <li>Raffle Prizes - BMR Give-aways for Earlybird draw, Pratte Ski Camp, Jozo Branded Merchandise</li>
        <li>Club Awards - Club Championship and Season Awards</li>
        <li>Race Day Gear - Racer Warm-up Ponchos, Gate Judge Packages</li>
      </ul>
      <p>
        In addition, as a Member, and showing your Membership card, you are entitled to an exclusive Discount Program (10-15%) at the following Blue Mountain Retailers as well as other Local Independent Vendors including:
      </p>
      <ul>
        <li>Blue Mountain Supply Co.</li>
        <li>The North Face</li>
        <li>Red Devil Sports</li>
        <li>Columbia</li>
        <li>Lifted Sports</li>
        <li>Hillside Outfitters</li>
        <li>Bull Wheel</li>
        <li>Jozo’s Apres Ski</li>
        <li>Pottery Restaurant</li>
        <li>Grand Central Lodge</li>
        <li>South Base Lodge</li>
        <li>Kombi</li>
        <li>Warming Centre Demo Hut @ Central</li>
        <li>Kaytoo Restaurant</li>
        <li>Ricks Ski Pro Shop</li>
      </ul>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">terrain</mat-icon>
        Handbooks & Guides
      </ng-template>
      <p>
        The following handbooks and guides were created to be a quick reference for parents, team managers, and gate judges, to help ease the Saturday/Sunday morning rush of getting our kids on the hill.
      </p>
      <p>
        <a *ngIf="'club/2022-Jozo-Parent-Handbook-vJan2022.pdf' | fireStorageImage | async as photoUrl" [href]="photoUrl" target="blank">Click here to download the Jozo Parent Handbook</a>
      </p>
      <p>
        <a *ngIf="'club/2022-Team-Manager-Handbook.pdf' | fireStorageImage | async as photoUrl" [href]="photoUrl" target="blank">Click here to download the Team Manager Handbook</a>
      </p>
      <p>
        <a *ngIf="'club/2022-Jozo-Gate-Judge-Guide.pdf' | fireStorageImage | async as photoUrl" [href]="photoUrl" target="blank">Click here to download the Gate Judge Guide</a>
      </p>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">terrain</mat-icon>
        Award Program
      </ng-template>
      <p>
        Each year we attempt to recognize the accomplishments of our racers. Awards this year will be presented in much the same manner as in previous years.
      </p>
      <ul>
        <li>
          <b>1-Day U10, U12 and U14 athletes</b> will receive participation medals and top performers within each age category (boys and girls) will receive merit medals. We will also award Gold, Silver and Bronze medals for the Club Championships to the top racers.
        </li>
        <li>
          <b>2-Day U10, U12, U14, U16 & U19 athletes</b> will see a set of Team awards presented (Top Boy, Top Girl, Most Spirited, and Most Improved) at the year-end Banquet. These are chosen by the coaches. The Jozo Weider Volunteer Committee Award Program also supports a merit program designed to reward racers who perform well throughout the year and at Club Championships. The winners of the Best Boy, Best Girl, Most Spirited and Most Improved awards will also receive gift certificates in recognition of their accomplishments. The top 5 finishers at Club Championships in U10, U12, U14, U16 and U19 races, will also receive gift certificates. Results will be announced and awards will be presented at the year-end banquet on March 7, 2020.
        </li>
      </ul>
      <p>
        <b><i>Good luck to all our racers!</i></b>
      </p>
      <img class="award-image" src="assets/jozo_medals.jpg"/>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">terrain</mat-icon>
        Volunteer Committe
      </ng-template>
      <div class="committe">
        <h2 class="committe-header">Executive</h2>
        <div class="committe-members">
          <mat-grid-list [cols]="columns" (window:resize)="onResize($event)" [rowHeight]="rowHeight">
            <mat-grid-tile colspan="1" *ngFor="let executive of executives">
              <mat-card>
                <mat-card-header>
                  <mat-card-title>{{executive.name}}</mat-card-title>
                  <mat-card-subtitle>{{executive.title}}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                  <img *ngIf="'club/'+executive.photo | fireStorageImage | async as photoUrl" [src]="photoUrl" width="150" height="150" />
                </mat-card-content>
                <mat-card-footer>
                  <mat-icon>email</mat-icon><a href="mailto:{{executive.email}}?subject=Jozo Website Inquiry">{{executive.email}}</a>
                </mat-card-footer>
              </mat-card>
            </mat-grid-tile>
          </mat-grid-list>
        </div>

        <h2 class="committe-header">Committe</h2>
        <div class="committe-members">
          <mat-grid-list [cols]="columns" (window:resize)="onResize($event)" [rowHeight]="rowHeight">
            <mat-grid-tile colspan="1" *ngFor="let volunteer of volunteers">
              <mat-card>
                <mat-card-header>
                  <mat-card-title>{{volunteer.name}}</mat-card-title>
                  <mat-card-subtitle>{{volunteer.title}}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                  <img *ngIf="'club/'+volunteer.photo | fireStorageImage | async as photoUrl" [src]="photoUrl" width="150" height="150" />
                </mat-card-content>
                <mat-card-footer>
                  <mat-icon>email</mat-icon><a href="mailto:{{volunteer.email}}?subject=Jozo Website Inquiry">{{volunteer.email}}</a>
                </mat-card-footer>
              </mat-card>
            </mat-grid-tile>
          </mat-grid-list>
        </div>

        <h2 class="committe-header">Blue Mountain Racing</h2>
        <div class="committe-members">
          <mat-grid-list [cols]="columns" (window:resize)="onResize($event)" [rowHeight]="rowHeight">
            <mat-grid-tile colspan="1" *ngFor="let bmr of bmrs">
              <mat-card>
                <mat-card-header>
                  <mat-card-title>{{bmr.name}}</mat-card-title>
                  <mat-card-subtitle>{{bmr.title}}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                  <img *ngIf="'club/'+bmr.photo | fireStorageImage | async as photoUrl" [src]="photoUrl" width="150" height="150" />
                </mat-card-content>
                <mat-card-footer>
                  <mat-icon>email</mat-icon><a href="mailto:{{bmr.email}}?subject=Jozo Website Inquiry">{{bmr.email}}</a>
                </mat-card-footer>
              </mat-card>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
