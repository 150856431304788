import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit {

  loading = true;
  error: any;
  dataSource: any;
  displayedColumns: string[] = ['firstName', 'lastName', 'ageGroup', 'registeredDateTime','parentName', 'parentEmail','parentPhoneNumber'];
  members = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private db: AngularFirestore) {
    const load = this.db.collection('members-2021').valueChanges();
    load.subscribe(members => {
      this.members = members;
      this.dataSource = new MatTableDataSource(this.members);
      this.loading = false;
      this.error = "";
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      console.log('members' + members);
    });
  }

  ngOnInit(): void {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
