import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-online-store',
  templateUrl: './online-store.component.html',
  styleUrls: ['./online-store.component.scss']
})
export class OnlineStoreComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
