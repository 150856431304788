<div id="home-main">
  <!--
  <div id="annoucements">
    <h2>Jozo 2021 Club Memberships</h2>
    <mat-icon>how_to_reg</mat-icon><a class="news-link" href="members/register">Click Here to Register Now</a>
  </div>
  -->
</div>
<div id="home-news">
  <div id="newsletters">
    <span id="join-newsletter">
      <mat-icon class="example-tab-icon">drafts</mat-icon><a class="news-link" href="https://jwracers.us4.list-manage.com/subscribe?u=04a4e89b738dcc839fd4b61d9&id=e0c02eb640" target="blank">Join Our Newsletter</a>
    </span>
    <span id="past-newsletter">
      <mat-icon class="example-tab-icon">drafts</mat-icon><a class="news-link" href="https://us4.campaign-archive.com/home/?u=04a4e89b738dcc839fd4b61d9&id=e0c02eb640" target="blank">News Letter Archives</a>
    </span>
  </div>
  <div id="contact-us">
    <h1>Contact Us</h1>
    <form *ngIf="!sent" class="contact-form" (ngSubmit)="send()">
      <mat-form-field class="contact-us-form">
        <mat-label>Please provide your name</mat-label>
        <input matInput placeholder="First / Last Name" required [(ngModel)]="name" name="name">
      </mat-form-field>
      <mat-form-field class="contact-us-form">
        <mat-label>Leave us your e-mail so we can reply</mat-label>
        <input matInput placeholder="info@acme.inc" required [(ngModel)]="email" name="email">
      </mat-form-field>
      <mat-form-field class="contact-us-form">
        <mat-label>Please tell us your message</mat-label>
        <textarea matInput placeholder="I would like to inquire about...." [(ngModel)]="message" required cdkTextareaAutosize cdkAutosizeMinRows="8" name="message"></textarea>
      </mat-form-field>
      <button mat-stroked-button type="submit">Send</button>
    </form>
    <p *ngIf="sent" id="email-sent">
      Thank You! We will respond as soon as possible.
    </p>
  </div>
</div>
<div id="home-twitter">
  <a class="twitter-timeline" data-height="800" width="800" data-theme="light" href="https://twitter.com/jozoweider?ref_src=twsrc%5Etfw">
    Tweets by jozoweider
  </a>
</div>
<!--
<div id="home-insta">
  Instagram Feed
</div>
-->
<div id="home-sponsors">
  <h2>Our Amazing Sponsors</h2>
  <mat-grid-list [cols]="columns" (window:resize)="onResize($event)" rowHeight="200px">
      <mat-grid-tile colspan="1" *ngFor="let sponsor of sponsors">
        <mat-card>
          <img mat-card-image *ngIf="'club/'+sponsor.photo | fireStorageImage | async as photoUrl" [src]="photoUrl" width="150" height="150" />
        </mat-card>
      </mat-grid-tile>
  </mat-grid-list>
</div>
