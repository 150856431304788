<app-header></app-header>
<router-outlet></router-outlet>

<!-- Footer -->
<div id="footer">
    <div id="social-media">
      <a href="https://www.facebook.com/JozoWeiderRacingClub/" target="blank" class="fa fa-facebook"></a>
      <a href="https://twitter.com/jozoweider" target="blank" class="fa fa-twitter"></a>
      <a href="https://www.instagram.com/jozoweiderracingclub/" target="blank" class="fa fa-instagram"></a>
      <a href="https://www.youtube.com/channel/UCRn0DQbDvLvTGJeFXInzNmg" target="blank" class="fa fa-youtube"></a>
    </div>
    <div id="footer-copyright">&copy; 2015-{{today | date:'yyyy'}} Jozo Weider Volunteer Committee. All Rights Reserved</div>
    <div id="footer-message">This site is not the property of Blue Mountain Resorts Limited and the content contained within is not the responsibility of the resort.</div>
</div>
