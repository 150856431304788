import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-members-download',
  templateUrl: './members-download.component.html',
  styleUrls: ['./members-download.component.scss']
})
export class MembersDownloadComponent implements OnInit {

  loading = true;
  error: any;
  dataSource: any;
  members = [];

  constructor(private db: AngularFirestore) {
    const load = this.db.collection('members-2021').valueChanges();
    load.subscribe(members => {
      this.members = members;
      this.loading = false;
      this.error = "";
      console.log('members' + members);
    });
  }

  ngOnInit(): void {
  }

  download() {
    let data = this.members;
    // sort by date
    data.sort(function (a,b) {
      return Date.parse(a.registeredDateTime) - Date.parse(b.registeredDateTime);
     }
    );
    this.downloadFile(data);
  }

  downloadFile(data, filename='data') {
    let csvData = this.convertToCSV(data, ['registeredDateTime', 'firstName', 'lastName', 'ageGroup', 'parentFirstName', 'paretLastName', 'parentEmail', 'parentPhone', 'parentAcceptedHandbook', 'parentAcceptedHandbook']);
    console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }


  convertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';
    for (let index in headerList) {
        row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
        let line = '';
        for (let index in headerList) {
          let head = headerList[index];
          let val = array[i][head];
          if(head == 'registeredDateTime') {
            let msec = Date.parse(val);
            const theDate = new Date(msec);
            val = theDate.toLocaleString();
          }
          if (val.indexOf(',') > -1) {
            val = '"' + val + '"';
          }
          if(line.length > 0) {
            line += ','
          }
          line += val;
        }
        str += line + '\r\n';
    }
    return str;
  }
}
