<div class="header">
  <div class="menu" [class.small]="size == 's'" >
    <img id="logo" src="assets/jozo_latest_logo.png"/>
    <ul>
      <li>
        <a href="#" routerLink="/members/download" routerLinkActive="active">Members Download</a>
      </li>
      <li>
        <a href="#" routerLink="/members/listing" routerLinkActive="active">Members Listing</a>
      </li>
    </ul>
  </div>
</div>
