import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-the-club',
  templateUrl: './the-club.component.html',
  styleUrls: ['./the-club.component.scss']
})
export class TheClubComponent implements OnInit {

  folder: any;
  executives = [];
  volunteers = [];
  bmrs = [];
  columns: any;
  rowHeight: any;

  constructor(private db: AngularFirestore, private storage: AngularFireStorage) {
    const loadExecutives = this.db.collection('executive-committe').valueChanges();
    loadExecutives.subscribe(executives => {
      this.executives = executives;
      console.log(executives);
    });

    const loadVolunteers = this.db.collection('volunteer-committe').valueChanges();
    loadVolunteers.subscribe(volunteers => {
      this.volunteers = volunteers;
      console.log(volunteers);
    });

    const loadBmr = this.db.collection('bmr-committe').valueChanges();
    loadBmr.subscribe(bmrs => {
      this.bmrs = bmrs;
      console.log(bmrs);
    });
    this.folder = this.storage.ref('club');
  }

  ngOnInit(): void {
    this.columns = (window.innerWidth <= 400) ? 1 : 3;
    this.rowHeight = (window.innerWidth <= 400) ? "1:2" : "1:1";
  }

  onResize(event) {
    this.columns = (event.target.innerWidth <= 400) ? 1 : 3;
    this.rowHeight = (window.innerWidth <= 400) ? "1:2" : "1:1";
  }
}
