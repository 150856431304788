import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../services/firebase.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: string;
  password: string;

  constructor(public fbs: FirebaseService) { }

  ngOnInit(): void {
  }

  login() {
    this.fbs.login(this.email, this.password);
    this.email = this.password = '';
  }

  logout() {
    this.fbs.logout();
  }

}
