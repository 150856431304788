import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SponsorsComponent } from './sponsors/sponsors.component';
import { OnlineStoreComponent } from './online-store/online-store.component';
import { TheClubComponent } from './the-club/the-club.component';
import { RegisterMemberComponent } from './register-member/register-member.component';
import { MembersComponent } from './members/members.component';
import { MembersDownloadComponent } from './members-download/members-download.component';
import { LoginComponent } from './login/login.component';
import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  {
    path: '', component: HomeComponent
  },
  {
    path: 'home', component: HomeComponent
  },
  {
    path: 'sponsors', component: SponsorsComponent
  },
  {
    path: 'club', component: TheClubComponent
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'members/register', component: RegisterMemberComponent
  },
  {
    path: 'members/download', component: MembersDownloadComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'members/listing', component: MembersComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
  //providers: [ AuthGuard ]
})
export class AppRoutingModule { }
