<div class="page-header">
  <h1>Membership Registration</h1>
</div>

<div class="register-member" *ngIf="!sent">
  <p>To join our awesome club, please fill out details below</p>
  <mat-horizontal-stepper #stepper linear>
    <!--<form class="example-form" [formGroup]="parentFormGroup"">-->
    <!--<h3>Parent Information</h3>-->
    <mat-step [stepControl]="parentFormGroup" label="Parent Information">
      <form [formGroup]="parentFormGroup">
        <mat-form-field class="example-full-width">
          <mat-label>First Name</mat-label>
          <input matInput placeholder="Parent First Name" formControlName="parentFirstName" required>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label>Last Name</mat-label>
          <input matInput placeholder="Parent Last Name" formControlName="parentLastName" required>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label>Email</mat-label>
          <input matInput placeholder="parent@jwracers.ca" formControlName="parentEmail" required>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label>Phone Number</mat-label>
          <input matInput placeholder="416-000-0000" formControlName="parentPhone" required>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label>How many racers in your family?</mat-label>
          <select matNativeControl formControlName="numberOfRacers" required>
            <option>-- Please Select --</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </mat-form-field>
        <div>
          <button mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="racerFormGroup" label="Racer Information">
      <form [formGroup]="racerFormGroup">
        <div id="racer1">
          <h3>Racer 1</h3>
          <mat-form-field class="example-full-width">
            <mat-label>First Name</mat-label>
            <input matInput placeholder="Racer First Name" formControlName="racer1FirstName" required>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label>Last Name</mat-label>
            <input matInput placeholder="Racer Last Name" required formControlName="racer1LastName">
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label>Age Group</mat-label>
            <select matNativeControl required formControlName="racer1AgeGroup">
              <option>-- Please Select --</option>
              <option>U8 (1 day)</option>
              <option>U10 (1 day)</option>
              <option>U12 (1 day)</option>
              <option>U14 (1 day)</option>
              <option>U8 (2 day)</option>
              <option>U10 (2 day)</option>
              <option>U12</option>
              <option>U14</option>
              <option>U14 Ocup</option>
              <option>U16</option>
              <option>U16 Ocup</option>
              <option>U19/U21</option>
              <option>Freestyle</option>
              <option>Ski Cross</option>
              <option>Snowboard Cross</option>
              <option>Masters, Alumni</option>
            </select>
          </mat-form-field>
        </div>
        <div id="racer2" *ngIf="parentFormGroup.value.numberOfRacers == '2' || parentFormGroup.value.numberOfRacers == '3' || parentFormGroup.value.numberOfRacers == '4' || parentFormGroup.value.numberOfRacers == '5'">
          <h3>Racer 2</h3>
          <mat-form-field class="example-full-width">
            <mat-label>First Name</mat-label>
            <input matInput placeholder="Racer First Name" formControlName="racer2FirstName" required>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label>Last Name</mat-label>
            <input matInput placeholder="Racer Last Name" formControlName="racer2LastName" required>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label>Age Group</mat-label>
            <select matNativeControl formControlName="racer2AgeGroup" required>
              <option>-- Please Select --</option>
              <option>U8 (1 day)</option>
              <option>U10 (1 day)</option>
              <option>U12 (1 day)</option>
              <option>U14 (1 day)</option>
              <option>U8 (2 day)</option>
              <option>U10 (2 day)</option>
              <option>U12</option>
              <option>U14</option>
              <option>U14 Ocup</option>
              <option>U16</option>
              <option>U16 Ocup</option>
              <option>U19/U21</option>
              <option>Freestyle</option>
              <option>Ski Cross</option>
              <option>Snowboard Cross</option>
              <option>Masters, Alumni</option>
            </select>
          </mat-form-field>
        </div>
        <div id="racer3" *ngIf="parentFormGroup.value.numberOfRacers == '3' || parentFormGroup.value.numberOfRacers == '4' || parentFormGroup.value.numberOfRacers == '5'">
          <h3>Racer 3</h3>
          <mat-form-field class="example-full-width">
            <mat-label>First Name</mat-label>
            <input matInput placeholder="Racer First Name" formControlName="racer3FirstName">
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label>Last Name</mat-label>
            <input matInput placeholder="Racer Last Name" formControlName="racer3LastName">
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label>Age Group</mat-label>
            <select matNativeControl formControlName="racer3AgeGroup">
              <option>-- Please Select --</option>
              <option>U8 (1 day)</option>
              <option>U10 (1 day)</option>
              <option>U12 (1 day)</option>
              <option>U14 (1 day)</option>
              <option>U8 (2 day)</option>
              <option>U10 (2 day)</option>
              <option>U12</option>
              <option>U14</option>
              <option>U14 Ocup</option>
              <option>U16</option>
              <option>U16 Ocup</option>
              <option>U19/U21</option>
              <option>Freestyle</option>
              <option>Ski Cross</option>
              <option>Snowboard Cross</option>
              <option>Masters, Alumni</option>
            </select>
          </mat-form-field>
        </div>
        <div id="racer4" *ngIf="parentFormGroup.value.numberOfRacers == '4' || parentFormGroup.value.numberOfRacers == '5'">
          <h3>Racer 4</h3>
          <mat-form-field class="example-full-width">
            <mat-label>First Name</mat-label>
            <input matInput placeholder="Racer First Name" formControlName="racer4FirstName">
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label>Last Name</mat-label>
            <input matInput placeholder="Racer Last Name" formControlName="racer4LastName">
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label>Age Group</mat-label>
            <select matNativeControl formControlName="racer4AgeGroup">
              <option>-- Please Select --</option>
              <option>U8 (1 day)</option>
              <option>U10 (1 day)</option>
              <option>U12 (1 day)</option>
              <option>U14 (1 day)</option>
              <option>U8 (2 day)</option>
              <option>U10 (2 day)</option>
              <option>U12</option>
              <option>U14</option>
              <option>U14 Ocup</option>
              <option>U16</option>
              <option>U16 Ocup</option>
              <option>U19/U21</option>
              <option>Freestyle</option>
              <option>Ski Cross</option>
              <option>Snowboard Cross</option>
              <option>Masters, Alumni</option>
            </select>
          </mat-form-field>
        </div>
        <div id="racer5" *ngIf="parentFormGroup.value.numberOfRacers == '5'">
          <h3>Racer 5</h3>
          <mat-form-field class="example-full-width">
            <mat-label>First Name</mat-label>
            <input matInput placeholder="Racer First Name" formControlName="racer5FirstName">
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label>Last Name</mat-label>
            <input matInput placeholder="Racer Last Name" formControlName="racer5LastName">
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label>Age Group</mat-label>
            <select matNativeControl formControlName="racer5AgeGroup">
              <option>-- Please Select --</option>
              <option>U8 (1 day)</option>
              <option>U10 (1 day)</option>
              <option>U12 (1 day)</option>
              <option>U14 (1 day)</option>
              <option>U8 (2 day)</option>
              <option>U10 (2 day)</option>
              <option>U12</option>
              <option>U14</option>
              <option>U14 Ocup</option>
              <option>U16</option>
              <option>U16 Ocup</option>
              <option>U19/U21</option>
              <option>Freestyle</option>
              <option>Ski Cross</option>
              <option>Snowboard Cross</option>
              <option>Masters, Alumni</option>
            </select>
          </mat-form-field>
        </div>
        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="agreeFormGroup" label="Agree">
      <h4>Please Review the Parent Handbook</h4>
      <div id="parent-handbook">
        <pdf-viewer
          [src]="parentHandBookSrc"
          [(page)]="page"
          [rotation]="rotation"
          [original-size]="originalSize"
          [fit-to-page]="fitToPage"
          [zoom]="zoom"
          [show-all]="showAll"
          [stick-to-page]="stickToPage"
          [render-text]="renderText"
          [external-link-target]="'blank'"
          [autoresize]="autoresize">
        </pdf-viewer>
      </div>

      <p>I have read, understood and agree to abide by the Athlete Agreement as written in the Parent Handbook, and agree that to do so is a requirement of my participation in the Jozo Weider Alpine Snow Teams & Blue Mountain Resort Race Programs in 2022.</p>

      <button mat-button matStepperPrevious>Back</button>
      <button mat-stroked-button (click)="send()" matStepperNext>Agree</button>
    </mat-step>
    <mat-step label="Payment">
      <h4>Please proceed to our Online Store to pay for your membership.</h4>
      <div>
        <button mat-button onclick="window.open('https://jozo-weider-volunteer-committee.square.site/product/club-membership/4','_blank')">Online Store</button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
<div *ngIf="sent">
  <h2>Welcome to the club!</h2>
  <p>We look forward to an awesome season with you.</p>
</div>
