import { Component, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { PDFProgressData, PdfViewerComponent, PDFDocumentProxy, PDFSource } from 'ng2-pdf-viewer';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-register-member',
  templateUrl: './register-member.component.html',
  styleUrls: ['./register-member.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}
  }]
})
export class RegisterMemberComponent implements OnInit {

  folder: any;
  registeredDate: any;
  sent = false;
  parentFirstName = "";
  parentLastName = "";
  parentEmail = "";
  parentPhone = "";
  numberOfRacers = "";
  racer1FirstName = "";
  racer1LastName = "";
  racer1AgeGroup = "";
  racersJuniorMasks = "";
  racersAdultMasks = "";

  racer2FirstName = "";
  racer2LastName = "";
  racer2AgeGroup = "";

  racer3FirstName = "";
  racer3LastName = "";
  racer3AgeGroup = "";

  racer4FirstName = "";
  racer4LastName = "";
  racer4AgeGroup = "";

  racer5FirstName = "";
  racer5LastName = "";
  racer5AgeGroup = "";

  agreeToParentHandBook = false;
  parentHandBookSrc: string | PDFSource | ArrayBuffer = '../assets/docs/2022-Jozo-Parent-Handbook-vJan2022.pdf';
  page = 1;
  rotation = 0;
  zoom = 1.0;
  originalSize = true;
  pdf: any;
  renderText = true;
  isLoaded = false;
  stickToPage = false;
  showAll = true;
  autoresize = true;
  fitToPage = false;
  outline: any[];
  isOutlineShown = true;
  joinedDate

  parentFormGroup: FormGroup;
  racerFormGroup: FormGroup;
  agreeFormGroup: FormGroup;
  doneFormGroup: FormGroup;

  @ViewChild(PdfViewerComponent) private pdfComponent: PdfViewerComponent;

  constructor(private _formBuilder: FormBuilder, private db: AngularFirestore, private storage: AngularFireStorage, private datePipe: DatePipe) {
    this.folder = this.storage.ref('club');
  }

  ngOnInit() {
    this.parentFormGroup = this._formBuilder.group({
      parentFirstName: ['', Validators.required],
      parentLastName: ['', Validators.required],
      parentEmail: ['', Validators.required],
      parentPhone: ['', Validators.required],
      numberOfRacers: ['', Validators.required]
    });

    this.racerFormGroup = this._formBuilder.group({
      racer1FirstName: ['', Validators.required],
      racer1LastName: ['', Validators.required],
      racer1AgeGroup: ['', Validators.required],
      racer2FirstName: [''],
      racer2LastName: [''],
      racer2AgeGroup: [''],
      racer3FirstName: [''],
      racer3LastName: [''],
      racer3AgeGroup: [''],
      racer4FirstName: [''],
      racer4LastName: [''],
      racer4AgeGroup: [''],
      racer5FirstName: [''],
      racer5LastName: [''],
      racer5AgeGroup: ['']
    });
  }

  send() {
    let registeredDate = new Date(Date.now());
    const registration = 'Parent First Name: ' + this.parentFormGroup.value.parentFirstName + '<br>' +
      'Parent Last Name: ' + this.parentFormGroup.value.parentLastName + '<br>' +
      'Email: ' + this.parentFormGroup.value.parentEmail + '<br>' +
      'Phone: ' + this.parentFormGroup.value.parentPhone;

      this.db.collection('members-2021').add({
              'parentFirstName': this.parentFormGroup.value.parentFirstName,
              'paretLastName': this.parentFormGroup.value.parentLastName,
              'parentEmail': this.parentFormGroup.value.parentEmail,
              'parentPhone': this.parentFormGroup.value.parentPhone,
              'parentAcceptedHandbook': 'true',
              'firstName': this.racerFormGroup.value.racer1FirstName,
              'lastName': this.racerFormGroup.value.racer1LastName,
              'ageGroup': this.racerFormGroup.value.racer1AgeGroup,
              'registeredDateTime': registeredDate.toString(),
              'memberStatus': 'Submitted'
      })
      .then(memberRes => {
          console.log('Member Added succesfully: ' + memberRes.id);
      })
      .catch(e => {
          console.log(e);
      })

      if(this.parentFormGroup.value.numberOfRacers >= 2) {
        this.db.collection('members-2021').add({
                'parentFirstName': this.parentFormGroup.value.parentFirstName,
                'paretLastName': this.parentFormGroup.value.parentLastName,
                'parentEmail': this.parentFormGroup.value.parentEmail,
                'parentPhone': this.parentFormGroup.value.parentPhone,
                'parentAcceptedHandbook': 'true',
                'firstName': this.racerFormGroup.value.racer2FirstName,
                'lastName': this.racerFormGroup.value.racer2LastName,
                'ageGroup': this.racerFormGroup.value.racer2AgeGroup,
                'registeredDateTime': registeredDate.toString(),
                'memberStatus': 'Submitted'
        })
        .then(memberRes2 => {
            console.log('Member Added succesfully: ' + memberRes2.id);
        })
        .catch(e => {
            console.log(e);
        })
      }

      if(this.parentFormGroup.value.numberOfRacers >= 3) {
        this.db.collection('members-2021').add({
                'parentFirstName': this.parentFormGroup.value.parentFirstName,
                'paretLastName': this.parentFormGroup.value.parentLastName,
                'parentEmail': this.parentFormGroup.value.parentEmail,
                'parentPhone': this.parentFormGroup.value.parentPhone,
                'parentAcceptedHandbook': 'true',
                'firstName': this.racerFormGroup.value.racer3FirstName,
                'lastName': this.racerFormGroup.value.racer3LastName,
                'ageGroup': this.racerFormGroup.value.racer3AgeGroup,
                'registeredDateTime': registeredDate.toString(),
                'memberStatus': 'Submitted'
        })
        .then(memberRes3 => {
            console.log('Member Added succesfully: ' + memberRes3.id);
        })
        .catch(e => {
            console.log(e);
        })
      }

      if(this.parentFormGroup.value.numberOfRacers >= 4) {
        this.db.collection('members-2021').add({
                'parentFirstName': this.parentFormGroup.value.parentFirstName,
                'paretLastName': this.parentFormGroup.value.parentLastName,
                'parentEmail': this.parentFormGroup.value.parentEmail,
                'parentPhone': this.parentFormGroup.value.parentPhone,
                'parentAcceptedHandbook': 'true',
                'firstName': this.racerFormGroup.value.racer4FirstName,
                'lastName': this.racerFormGroup.value.racer4LastName,
                'ageGroup': this.racerFormGroup.value.racer4AgeGroup,
                'registeredDateTime': registeredDate.toString(),
                'memberStatus': 'Submitted'
        })
        .then(memberRes4 => {
            console.log('Member Added succesfully: ' + memberRes4.id);
        })
        .catch(e => {
            console.log(e);
        })
      }

      if(this.parentFormGroup.value.numberOfRacers >= 5) {
        this.db.collection('members-2021').add({
                'parentFirstName': this.parentFormGroup.value.parentFirstName,
                'paretLastName': this.parentFormGroup.value.parentLastName,
                'parentEmail': this.parentFormGroup.value.parentEmail,
                'parentPhone': this.parentFormGroup.value.parentPhone,
                'parentAcceptedHandbook': 'true',
                'firstName': this.racerFormGroup.value.racer5FirstName,
                'lastName': this.racerFormGroup.value.racer5LastName,
                'ageGroup': this.racerFormGroup.value.racer5AgeGroup,
                'registeredDateTime': registeredDate.toString(),
                'memberStatus': 'Submitted'
        })
        .then(memberRes5 => {
            console.log('Member Added succesfully: ' + memberRes5.id);
        })
        .catch(e => {
            console.log(e);
        })
      }

    console.log("Registered: " + registration);
    //this.sent = true;
    this.parentFormGroup.reset();
    this.racerFormGroup.reset();
  }
}
